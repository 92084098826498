* {
  margin: 0;
  padding: 0;
}

a, a:hover, a:active, a:focus {
  all: unset;
}

body {
  background: #141414;
  font-family: 'Montserrat', sans-serif;
}

.main-section {
  background-image: url('./assets/background.png');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.main-section .main-section__container {
  text-align: center;
  align-content: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.main-section .main-section__container .container__logotype {
  margin-bottom: 64px;
}

.main-section .main-section__container .container__text h1 {
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  color: #fff;
  margin-bottom: 16px;
}

.main-section .main-section__container .container__text p {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #fff;
  max-width: 620px;
  margin-bottom: 32px;
}

.main-section .main-section__container .link {
  display: flex;
  padding: 10px;
  background: #333333;
  border-radius: 8px;
  width: max-content;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  margin-bottom: 16px;
  cursor: pointer;
  transition: 0.3s;
}

.main-section .main-section__container .link:hover {
  background: #292929;
}


.main-section .main-section__container .link img {
  padding-right: 8px;
}

.main-section .main-section__container .container__questions {
  display: flex;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #939393;
}

.main-section .main-section__container .container__questions a {
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #267CFB;
  padding-left: 4px;
  cursor: pointer;
  transition: 0.3s;
}

.main-section .main-section__container .container__questions a:hover {
  color: #5a9af6;
}


@media (min-width: 260px) and (max-width: 576px) {
  .main-section .main-section__container .container__text h1 {
    font-size: 28px;
    padding: 0 10px;
  }

  .main-section .main-section__container .container__text p {
    font-size: 14px;
    max-width: 400px;
    padding: 0 10px;
  }
}